import './App.css';
import Layout from './Components/Layout';

function App() {

  return (
    <Layout />
  );
}

export default App;
